@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
        padding-top: 0px;
    }
}

html {
    background-color: white;
    overflow-x: hidden;
}
body {
    height: 100vh;
    width: 100vh;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    position: relative;
}

@font-face {
    font-family: "Impact";
    src: local("Impact"), url("./fonts/impact.ttf") format("truetype");
    font-weight: normal;
}