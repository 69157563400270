h1 {
    margin: 0px;
}

#finishedWorksContainer {
    display: flex;
    flex-direction: column;
    background-color: #FFF024;
    border: 5px solid #262424;
    overflow: hidden;
}

#finishedWorksCarouselPaddingContainer {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 2%;
    padding-bottom: 4%;
    display: flex;
    flex-direction: row;
}

#finishedWorksCarouselContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid rgba(38,36,36,0);
    flex-grow: 1;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    opacity: .8;
    transform: scale(40%) translateY(-100%);
}

#finishedWorksFiller {
    width: 5%;
}

#finishedWorksText {
    padding-top: 2%;
    padding-bottom: 1%;
    text-indent: 8%;
    transition: font-size linear .5s;
    cursor: default;
}

#finishedWorksLocationContainer {
    display: flex;
    flex-direction: row;
    padding-left: 8%;
    padding-right: 8%;
    flex-wrap: wrap;
}

#finishedWorksFiller {
    flex-grow: 1;
}

#finishedWorksLocationText {
    opacity: 1;
    transition: opacity linear .5s;
    transition: font-size linear .5s;
    cursor: pointer;
    flex-grow: 1;
}

#finishedWorksLocationTextRight {
    opacity: 1;
    transition: opacity linear .5s;
    transition: font-size linear .5s;
    cursor: pointer;
    text-align: right;
    flex-grow: 1;
}

#finishedWorksLocationText:hover, #finishedWorksLocationTextRight:hover {
        opacity: 1;
        transition: opacity linear .5s;
        transition: font-size linear .5s;
        cursor: pointer;
}

#finishedWorksLocationTextActive {
    opacity: 1;
    transition: opacity linear .5s;
    transition: font-size linear .5s;
    cursor: pointer;
    flex-grow: 1;
}

#finishedWorksLocationTextRightActive {
    opacity: 1;
    transition: opacity linear .5s;
    transition: font-size linear .5s;
    cursor: pointer;
    flex-grow: 1;
    text-align: right;
}





@media screen AND (max-width: 4000px) {
    #finishedWorksText {
        font-size: 100px;
    }

    #finishedWorksLocationText, #finishedWorksLocationTextActive, #finishedWorksLocationTextRight, #finishedWorksLocationTextRightActive {
        font-size: 50px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 2800px) {
    #finishedWorksText {
        font-size: 90px;
    }

    #finishedWorksLocationText, #finishedWorksLocationTextActive, #finishedWorksLocationTextRight, #finishedWorksLocationTextRightActive {
        font-size: 40px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 1750px) {
    #finishedWorksText {
        font-size: 80px;
    }

    #finishedWorksLocationText, #finishedWorksLocationTextActive, #finishedWorksLocationTextRight, #finishedWorksLocationTextRightActive {
        font-size: 30px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 1200px) {
    #finishedWorksText {
        font-size: 60px;
    }

    #finishedWorksLocationText, #finishedWorksLocationTextActive, #finishedWorksLocationTextRight, #finishedWorksLocationTextRightActive {
        font-size: 20px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 970px) {
    #finishedWorksText {
        font-size: 45px;
    }

    #finishedWorksLocationText, #finishedWorksLocationTextActive, #finishedWorksLocationTextRight, #finishedWorksLocationTextRightActive {
        font-size: 15px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 650px) {
    #finishedWorksText {
        font-size: 25px;
    }

    #finishedWorksLocationText, #finishedWorksLocationTextActive, #finishedWorksLocationTextRight, #finishedWorksLocationTextRightActive {
        font-size: 10px;
    }
}
