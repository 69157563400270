h1 {
    margin: 0px;
}

#objectsContainer {
    display: flex;
    flex-direction: column;
    background-color: #FFF024;
    border-bottom: 5px solid #262424;
    border-top: 5px solid #262424;
    overflow: hidden;
    align-items: center;
}

#objectsCarouselPaddingContainer {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 2%;
    padding-bottom: 4%;
    flex-direction: row;
    align-items: center;
    max-width: 70vh;
}

#objectsCarouselContainer {
    border: 1px solid rgba(38,36,36,0);

}

#objectsText {
    padding-top: 2%;
    padding-bottom: 1%;
    transition: font-size linear .5s;
    cursor: default;
    text-align: center;
}

#objectsLocationContainer {
    display: flex;
    flex-direction: row;
    padding-left: 8%;
    padding-right: 8%;
    flex-wrap: wrap;
}

#objectsLocationText {
    opacity: .6;
    transition: opacity linear .5s;
    transition: font-size linear .5s;
    cursor: pointer;
}

    #objectsLocationText:hover {
        opacity: 1;
        transition: opacity linear .5s;
        transition: font-size linear .5s;
        cursor: pointer;
    }

#objectsLocationTextActive {
    opacity: 1;
    transition: opacity linear .5s;
    transition: font-size linear .5s;
    cursor: pointer;
}



@media screen AND (max-width: 4000px) {
    #objectsText {
        font-size: 100px;
    }

    #objectsLocationText, #objectsLocationTextActive {
        font-size: 50px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 2800px) {
    #objectsText {
        font-size: 90px;
    }

    #objectsLocationText, #objectsLocationTextActive {
        font-size: 40px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 1750px) {
    #objectsText {
        font-size: 80px;
    }

    #objectsLocationText, #objectsLocationTextActive {
        font-size: 30px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 1200px) {
    #objectsText {
        font-size: 60px;
    }

    #objectsLocationText, #objectsLocationTextActive {
        font-size: 20px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 970px) {
    #objectsText {
        font-size: 45px;
    }

    #objectsLocationText, #objectsLocationTextActive {
        font-size: 15px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 650px) {
    #objectsText {
        font-size: 25px;
    }

    #objectsLocationText, #objectsLocationTextActive {
        font-size: 10px;
    }
}
