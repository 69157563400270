.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}


.contactDetailsContainerActive {
    font-size: 15pt;
    transition: ease all .5s;
    margin-left: 5%;
    margin-right: 5%;
    line-height: 200%;
    width: 100%;
}

.contactDetailsContainerInactive {
    font-size: 15pt;
    opacity: 0;
    transition: ease all .5s;
    margin-left: 2%;
    margin-right: 2%;
    line-height: 200%;
    width: 100%;
}

.contactDetailsContainerHidden {
    font-size: 0pt;
    opacity: 0;
}

.contactText{
    margin-left: 5;
    margin-right: 5%;
}


/* media queries - font size*/

@media screen AND (max-width: 4000px) {
    .contactDetailsContainerActive, .contactDetailsContainerInactive {
        font-size: 14pt;
        transition: ease all .5s;
    }
}

@media screen AND (max-width: 1177px) {
    .contactDetailsContainerActive, .contactDetailsContainerInactive {
        font-size: 13pt;
        transition: ease all .5s;
    }
}

@media screen AND (max-width: 970px) {
    .contactDetailsContainerActive, .contactDetailsContainerInactive {
        font-size: 10pt;
        transition: ease all .5s;
    }
}

@media screen AND (max-width: 650px) {
    .contactDetailsContainerActive, .contactDetailsContainerInactive {
        font-size: 9pt;
        transition: ease all .5s;
    }
}