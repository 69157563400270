body {
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0px;
    padding: 0px;
    height: 100vh;
    width: 100%;
}

ul {
    list-style-type: none;
    padding-left: 0px;
    text-align: center;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}

#aboutBubble {
    position: fixed;
    font-size: 30px;
    z-index: 1;
    left: 6%;
    top: 1%;
    background-color: #C2C6C7;
    font-family: Impact;
    max-width: 30%;
    padding: 1%;
    opacity: 1;
    transition: all .5s linear;
    pointer-events: none;
}

#aboutBubbleHidden {
    position: fixed;
    font-size: 30px;
    z-index: 1;
    left: 6%;
    top: 1%;
    background-color: #C2C6C7;
    font-family: Impact;
    max-width: 30%;
    padding: 1%;
    opacity: 0;
    transition: all .5s linear;
    pointer-events: none;
}

#contactBubble {
    position: fixed;
    font-size: 30px;
    z-index: 1;
    right: 6%;
    top: 1%;
    background-color: #262424;
    color: #FFF024;
    max-width: 30%;
    text-align: right;
    font-family: Impact;
    padding: 1%;
    opacity: 1;
    transition: all .5s linear;
    pointer-events: none;
}

#contactBubbleHidden {
    position: fixed;
    font-family: Impact;
    font-size: 30px;
    z-index: 1;
    right: 6%;
    top: 1%;
    background-color: #262424;
    color: #FFF024;
    max-width: 30%;
    text-align: right;
    padding: 1%;
    opacity: 0;
    transition: all .5s linear;
    pointer-events: none;
}

#masterLayoutContainer{

}

#greaterLayoutContainer {
    display: flex;
    flex-direction: column;
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-1290px); /* The image width */
    }
}
#layoutcontainer {
    background-color: #FFF024;
    height: 100vh;
    width: 100%;
    z-index: 0;
}
#layoutcontaineroverlay {
    background: url('../images/floorplancomposite4.png') repeat-x;
    background-size: contain;
    position: absolute;
    height: 100%;
    width: 10320px;
    animation: slide 60s linear infinite;
    z-index: -1;
}

#layouttopcontainer{
    height: 100%;
}

#sectionsContainer {
    z-index: -2;
    text-align: Left;
    font-family: Impact;
}

#leftBar {
    background-color: #C2C6C7;
    color: #262424;
    position: fixed;
    z-index: 1;
    font-family: Impact;
    text-indent: 4%;
    width: 5%;
    height: 100%;
    cursor: default;
    transition: font-size ease-in .5s, padding-top .5s, width ease-in .5s;


}

#leftBarActive {
    background-color: #C2C6C7;
    color: #262424;
    position: fixed;
    z-index: 1;
    font-family: Impact;
    text-indent: 4%;
    width: 5%;
    height: 100%;
    cursor: default;
    transition: font-size ease-in .5s, padding-top .5s, width ease-in .5s;
}

#leftBarFull {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    background-color: #C2C6C7;
    color: #262424;
    position: absolute;
    z-index: 1;
    left: 0;
    writing-mode: Horizontal-tb;
    font-family: Impact;
    height: 100%;
    width: 33%;
    text-indent: 3%;
    text-align: center;
    padding-top: 40vh;
    overflow: hidden;
    cursor: default;
    transition: font-size ease-in .5s, padding-top .5s, width ease-in .5s;
}

.sidespacerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.sidespacerContainerAlt {
    display: flex;
    flex-direction: column;
    padding-left: 25%;
    align-items: flex-start;
    transform: rotate(90deg);
    flex-grow: 1;
    transition: ease transform .5s;
}
.sidespacerContainerBarAlt {
    display: flex;
    flex-direction: column;
    padding-left: 25%;
    align-items: flex-start;
    transform: rotate(90deg);
    flex-grow: 1;
    transition: ease transform .5s;
}

#leftBarText {
    opacity: 1;
    transition: ease opacity .5s;
    display: flex;
    flex-direction: row;
    width: 100%;
    white-space: nowrap;
}

#leftBarTextFull {
    opacity: 1;
    transition: ease opacity .5s;
}


#rightBarText {
    opacity: 1;
    transition: ease opacity .5s;
    display: flex;
    flex-direction: row;
    width: 100%;
    white-space: nowrap;
}

#rightBarTextFull {
    padding-top: 0;
    opacity: 1;
    transition: ease opacity .5s;
}


#rightBar {
    background-color: #262424;
    color: #FFF024;
    position: fixed;
    z-index: 1;
    right: 0;
    font-family: Impact;
    width: 5%;
    height: 100%;
    text-indent: 4%;
    cursor: default;
    transition: font-size ease-in .5s, padding-top .5s, width ease-in .5s;
}

#rightBarFull {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    background-color: #262424;
    color: #FFF024;
    position: absolute;
    z-index: 1;
    right: 0;
    writing-mode: Horizontal-tb;
    font-family: Impact;
    height: 100%;
    width: 33%;
    text-indent: 3%;
    text-align: center;
    padding-top: 40vh;
    overflow: hidden;
    cursor: default;
    transition: font-size ease-in .5s, padding-top .5s, width ease-in .5s;
}

#barNameHidden{
    position: absolute;
    text-indent: 0%;
    opacity: 0;
    transition-delay: .5s;
    transition-property: opacity;
    transition-duration: .5s;
}

#barNameActive {
    text-indent: 6%;
    text-align: right;
    opacity: 1;
    transition-delay: .5s;
    transition-property: opacity;
    transition-duration: .5s;
}
    /* Size of Text for different media sizes*/
    @media screen AND (max-width: 4000px) {
        #rightBar, #leftBar,  {
            font-size: 40px;
        }

        #rightBarFull, #leftBarFull {
            font-size: 100px;
        }

        #aboutBubble, #contactBubble {
            font-size: 14pt;
            transition: ease all .5s;
        }
    }

@media screen AND (max-width: 2800px) {
    #rightBar, #leftBar {
        font-size: 60px;
    }

    #rightBarFull, #leftBarFull {
        font-size: 90px;
    }

    #aboutBubble, #contactBubble {
        font-size: 14pt;
        transition: ease all .5s;
    }
}

    @media screen AND (max-width: 1750px) {
        #rightBar, #leftBar {
            font-size: 45px;
        }

        #rightBarFull, #leftBarFull {
            font-size: 80px;
        }

        #aboutBubble, #contactBubble {
            font-size: 14pt;
            transition: ease all .5s;
        }
    }

        @media screen AND (max-width: 1200px) {
            #rightBar, #leftBar {
                font-size: 35px;
            }

            #rightBarFull, #leftBarFull {
                font-size: 60px;
            }

            #aboutBubble, #contactBubble {
                font-size: 12pt;
                transition: ease all .5s;
            }
    }

    @media screen AND (max-width: 970px) {
        #rightBar, #leftBar {
            font-size: 25px;
        }

        #rightBarFull, #leftBarFull {
            font-size: 45px;
        }

        #aboutBubble, #contactBubble {
            font-size: 10pt;
            transition: ease all .5s;
        }
    }

@media screen AND (max-width: 650px) {
    #rightBar, #leftBar {
        font-size: 10px;
    }

    #rightBarFull, #leftBarFull {
        font-size: 25px;
    }

    #aboutBubble, #contactBubble {
        font-size: 10pt;
        transition: ease all .5s;
    }
}