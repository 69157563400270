.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}


.aboutDetailsContainerActive {
    font-size: 12pt;
    transition: ease all .5s;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    text-overflow: clip;
    white-space: normal;
}

.aboutDetailsContainerInactive {
    font-size: 12pt;
    opacity: 0;
    transition: ease all .5s;
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
    text-overflow: clip;
        z-index: 1;
}

.aboutDetailsContainerHidden {
    font-size: 0pt;
    opacity: 0;
}

.aboutText{
    margin-left: 15%;
    margin-right: 15%;

}

/* media queries - font size*/

@media screen AND (max-width: 4000px) {
    .aboutDetailsContainerActive, .aboutDetailsContainerInactive {
        font-size: 14pt;
        transition: ease all .5s;
    }
}

@media screen AND (max-width: 1177px) {
    .aboutDetailsContainerActive, .aboutDetailsContainerInactive {
        font-size: 12pt;
        transition: ease all .5s;
    }
}

@media screen AND (max-width: 970px) {
    .aboutDetailsContainerActive, .aboutDetailsContainerInactive {
        font-size: 10pt;
        transition: ease all .5s;
    }
}

@media screen AND (max-width: 650px) {
    .aboutDetailsContainerActive, .aboutDetailsContainerInactive {
        font-size: 9pt;
        transition: ease all .5s;
    }
}