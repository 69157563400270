h1 {
    margin: 0px;
}

#WorksContainer{
    display: flex;
    flex-direction: column;
    
}

#worksFooter {
    height: 4vh;
    padding-top: .5%;
    padding-bottom: .5%;
    text-align: center;
    background-color: #FFF024;
    border-top: 5px solid #262424;
}

#ObjectsContainer{
}