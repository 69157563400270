h1 {
    margin: 0px;
}

#schemesContainer {
    display: flex;
    flex-direction: column;
    background-color: #FFF024;
    border-bottom: 5px solid #262424;
    border-top: 0x solid #262424;
    overflow: hidden;
}

#schemesCarouselPaddingContainer {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 2%;
    padding-bottom: 4%;
    flex-direction: row;
    align-items: center;
}

#schemesCarouselContainer {
    display: flex;
    flex-direction: row;
    border: 5px solid rgba(38,36,36,.0);
    width: 100%;
}



#schemesText {
    padding-top: 2%;
    padding-bottom: 1%;
    text-align: right;
    transition: font-size linear .5s;
    cursor: default;
    padding-right: 8%;
}

#schemesLocationContainer {
    display: flex;
    flex-direction: row;
    padding-left: 8%;
    padding-right: 8%;
    flex-wrap: wrap;
    justify-content: flex-end;
}

#schemesLocationText {
    opacity: .6;
    transition: opacity linear .5s;
    transition: font-size linear .5s;
    cursor: pointer;
}

    #schemesLocationText:hover {
        opacity: 1;
        transition: opacity linear .5s;
        transition: font-size linear .5s;
        cursor: pointer;
    }

#schemesLocationTextActive {
    opacity: 1;
    transition: opacity linear .5s;
    transition: font-size linear .5s;
    cursor: pointer;
}



@media screen AND (max-width: 4000px) {
    #schemesText {
        font-size: 100px;
    }

    #schemesLocationText, #schemesLocationTextActive {
        font-size: 50px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 2800px) {
    #schemesText {
        font-size: 90px;
    }

    #schemesLocationText, #schemesLocationTextActive {
        font-size: 40px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 1750px) {
    #schemesText {
        font-size: 80px;
    }

    #schemesLocationText, #schemesLocationTextActive {
        font-size: 30px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 1200px) {
    #schemesText {
        font-size: 60px;
    }

    #schemesLocationText, #schemesLocationTextActive {
        font-size: 20px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 970px) {
    #schemesText {
        font-size: 45px;
    }

    #schemesLocationText, #schemesLocationTextActive {
        font-size: 15px;
        line-height: 120%;
    }
}

@media screen AND (max-width: 650px) {
    #schemesText {
        font-size: 25px;
    }

    #schemesLocationText, #schemesLocationTextActive {
        font-size: 10px;
    }
}
