.navbar li .glyphicon {
    margin-right: 10px;
}

/* Highlighting rules for nav menu items */
.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:hover,
.navbar .navbar-nav .active a:focus {
    background-image: none;
    background-color: #4189C7;
    color: white;
}

a {
    color: #262424;
}

a:hover{
    color: #262424;
}

ul {
    list-style-type: none;
    padding-left: 0px;
    text-align: center;

}

#greaterNavContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#spacer1, #spacer2{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

#navContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding-top: 40vh;
    transition: ease all .5s;
}

#HashLinkContainerInactive {
    font-family: Impact;
    text-align: center;
    opacity: 0;
    transition: opacity linear .5s;
}

#HashLinkContainerActive {
    font-family: Impact;
    text-align: center;
    opacity: 1;
    transition: opacity linear .5s;
}


#WorksLabel {
    font-family: Impact;
    color: #262424;
    cursor: pointer;
    transition: padding-top ease .5s;
}

.navBarHidden {
    font-family: Impact;
    pointer-events: none;
    opacity: 0;
    transition: opacity .5s;
}

.navBarActive {
    font-family: Impact;

    pointer-events: auto;
    opacity: 1;
    transition: opacity .5s;
}


/*Nav Link Styling*/

a.inactiveNavLink {
    color: #262424;

}

a.activeNavLink {
    color: #262424;
}

/* Size of Text for different media sizes*/


@media screen AND (max-width: 4000px) {
    #WorksLabel {
        font-size: 100px;
    }
    a.inactiveNavLink, a.activenavLink, #HashLinkContainerActive {
        font-size: 30px;
    }
}

@media screen AND (max-width: 2800px) {
    #WorksLabel {
        font-size: 90px;
    }

    a.inactiveNavLink, a.activenavLink, #HashLinkContainerActive {
        font-size: 20px;
    }
}

@media screen AND (max-width: 1750px) {
    #WorksLabel {
        font-size: 80px;
    }

    a.inactiveNavLink, a.activenavLink, #HashLinkContainerActive {
        font-size: 15px;
    }
}

@media screen AND (max-width: 1200px) {
    #WorksLabel {
        font-size: 60px;
    }

    a.inactiveNavLink, a.activenavLink, #HashLinkContainerActive {
        font-size: 15px;
    }
}
@media screen AND (max-width: 970px) {
    #WorksLabel {
        font-size: 45px;
    }
    a.inactiveNavLink, a.activenavLink, #HashLinkContainerActive, #HashLinkContainerActive  {
        font-size: 15px;
    }
}

@media screen AND (max-width: 650px) {
    #WorksLabel {
        font-size: 25px;
    }
    a.inactiveNavLink, a.activenavLink, #HashLinkContainerActive {
        font-size: 11px;
    }
}
